import { useCallback, useState } from 'react'
import { useAtom } from 'jotai'

import { oneClickCookieAtom } from './one-click-lead-cookie.store'
import type { OneClickCookieData } from './one-click-lead-cookie.store'
import { useMakeOneClickLead } from './use-make-one-click-lead'
import { useZutronId } from '../user/user.store'
import { submitLeadWithErrorHandling } from '../lead-form/submit-lead'
import {
  trackErrors,
  trackSuccess,
  LeadCategoryRate,
} from '../lead-form/utils/event-tracking'
import { useIsEmailCtaBasicAvailableListing } from '../detail/hooks/use-is-email-cta-basic-available-listing'

import type { CtaButtons_ListingFragment } from '../cta-buttons/__generated__/cta-buttons.gql'
import { userAddEmailedEvent } from '../user-event-tracking/user-add-emailed-event'
import type { SimilarOrViewed_ListingFragment } from '../listing-card-preview-group/__generated__/similar-properties.gql'
import { leadCookieAtom } from '../lead-form/lead-cookie.store'
import { REQUEST_A_TOUR } from '../one-click-lead/one-click-lead.const'
import { useFavoriteListing } from '../../components/icon-ctas/use-favorite-listing'
import { useFeatureVariable } from '@rentpath/ab-testing-react'

type UseSubmitOneClickLead = {
  listing: CtaButtons_ListingFragment | SimilarOrViewed_ListingFragment
  type?: 'email' | 'tour'
}

const ONE_CLICK_LEAD_TAG = 'one-click-lead'

export function useSubmitOneClickLead({
  listing,
  type,
}: UseSubmitOneClickLead) {
  const [oneClickData, setOneClickCookieData] = useAtom(oneClickCookieAtom)
  const [leadCookieData, setLeadCookieData] = useAtom(leadCookieAtom)
  const showPaidLeadMessage = useIsEmailCtaBasicAvailableListing(listing)
  const oneClickLeadData = useMakeOneClickLead({
    oneClickData,
    listing,
    type,
    showPaidLeadMessage,
  })
  const zutronId = useZutronId()
  const [leadMessageSuccess, setLeadMessageSuccess] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const { mostRecentLeadTypeSubmitted } = oneClickData
  const favoriteListing = useFavoriteListing()
  const isFavLeadListingEnabled = useFeatureVariable<boolean>(
    ['fav_lead_listing', 'enabled'],
    false
  )

  const submitOneClickLead = useCallback(
    async (additionalLeadDetails?: Partial<OneClickCookieData>) => {
      setIsSending(true)
      const headers = zutronId ? { zid: zutronId } : undefined
      // this should never happen, but it is possible in our typing that
      // oneClickLeadData is null, so we need to check for it
      if (!oneClickLeadData) {
        trackErrors(listing, 'one-click-lead')

        return
      }

      if (additionalLeadDetails) {
        setOneClickCookieData({
          ...oneClickData,
          ...additionalLeadDetails,
        })

        // Future ticket for unifying these cookies once/if one click post-lead experience goes to 100%: https://rentgroup.atlassian.net/browse/WEB-12978
        setLeadCookieData({
          ...leadCookieData,
          ...additionalLeadDetails,
        })
      }

      const leadWithOptionalUpdates = {
        ...oneClickLeadData,
        ...(additionalLeadDetails || {}),
      }

      const intent =
        mostRecentLeadTypeSubmitted === REQUEST_A_TOUR
          ? ['request_tour']
          : ['email_one_click']

      const leadCategory =
        mostRecentLeadTypeSubmitted === REQUEST_A_TOUR
          ? LeadCategoryRate.REQUEST_TOUR
          : LeadCategoryRate.FORM

      submitLeadWithErrorHandling({ lead: leadWithOptionalUpdates }, headers)
        .tapOk(() => {
          setLeadMessageSuccess(true)
          setIsSending(false)

          if (isFavLeadListingEnabled) {
            void favoriteListing({
              listingId: listing.id,
              zutronId: zutronId || '',
            })
          }

          trackSuccess(listing, leadCategory, intent, ONE_CLICK_LEAD_TAG)
          userAddEmailedEvent({
            listingId: listing.id,
            zutronId: zutronId || '',
          })
        })
        .tapError(() => {
          setIsSending(false)
          // Per product - alert is fine here since this will not typically happen
          alert('There was a problem submitting your info.')

          trackErrors(listing, ONE_CLICK_LEAD_TAG)
        })
    },
    [
      listing,
      oneClickData,
      oneClickLeadData,
      setOneClickCookieData,
      leadCookieData,
      setLeadCookieData,
      zutronId,
      mostRecentLeadTypeSubmitted,
      favoriteListing,
      isFavLeadListingEnabled,
    ]
  )

  return {
    leadMessageSuccess,
    submitOneClickLead,
    isSending,
  }
}
