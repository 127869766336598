import { graphqlRequesterOptions } from '../../config/graphql-requester-options'
import { createUserAddFavoriteListingFetcher } from './__generated__/add-favorite.gql'
import { useAtom } from 'jotai'
import { userSavedListingIds } from '../../features/user/user.store'
import { startTransition, useCallback } from 'react'

type FavoriteProps = {
  listingId: string
  zutronId: string
}

const addFavorite = createUserAddFavoriteListingFetcher(graphqlRequesterOptions)

export function useFavoriteListing() {
  const [favorites, setFavorites] = useAtom(userSavedListingIds)

  const favoriteListing = useCallback(
    async ({ listingId, zutronId }: FavoriteProps) => {
      try {
        if (favorites.includes(listingId)) {
          return
        }

        await addFavorite({ listingId, zutronId }).tapOk(() => {
          startTransition(() => {
            setFavorites(favorites.concat([listingId]))
          })
        })
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Failed to favorite listing:', err)
      }
    },
    [favorites, setFavorites]
  )

  return favoriteListing
}
